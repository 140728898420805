<template>
  <product title="Amazfit 智能运动手表"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="799"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://j.youzan.com/5YT1Kh"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'pace',
  components: {
    Product
  },
  data () {
    return {
      slogan: '蓝牙听歌 | 索尼28纳米GPS | 陶瓷表圈 | 运动心率<br/>5天续航 | 智能通知提醒 | 支付宝快捷支付',
      colors: [
        '红色',
        '黑色'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/videos/pace.mp4',
        poster: CDN_URL + '/images/poster/pace.png'
      },
      swiperData: [
        [
          CDN_URL + '/images/product/pace/swiper/1.png',
          CDN_URL + '/images/product/pace/swiper/2.png',
          CDN_URL + '/images/product/pace/swiper/3.png',
          CDN_URL + '/images/product/pace/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/pace/swiper/5.png',
          CDN_URL + '/images/product/pace/swiper/6.png',
          CDN_URL + '/images/product/pace/swiper/7.png',
          CDN_URL + '/images/product/pace/swiper/8.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/pace/detail/01w.jpg',
        CDN_URL + '/images/product/pace/detail/02.jpg',
        CDN_URL + '/images/product/pace/detail/03.jpg',
        CDN_URL + '/images/product/pace/detail/04.jpg',
        CDN_URL + '/images/product/pace/detail/05.jpg',
        CDN_URL + '/images/product/pace/detail/06.jpg',
        CDN_URL + '/images/product/pace/detail/07.jpg',
        CDN_URL + '/images/common/zepp-white.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/pace/detail/09.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
s
